<template>
  <div>
    <div class="well_frame">
      <h4 class="frame_title">
        我的收藏
        <div class="list_operate_btns">
          <el-button type="text" @click="delInvalidFavor()">移除失效课程</el-button>
        </div>
      </h4>
      <el-table :data="list" class="order_table_common" @selection-change="selectChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="info" label="课程信息">
          <template #default="scope">
            <div class="course_item_common pointer">
              <div class="cover" @click="goPath('/course/detail/'  + scope.row.uuid)">
                <img :src="scope.row.cover_image" />
                <div v-if="scope.row.cover_image_label != ''">
                  <span class="hot" v-if="scope.row.cover_image_label && scope.row.cover_image_label != 'null'">{{ scope.row.cover_image_label }}</span>
                </div>
                <span class="tag" v-if="scope.row.type">{{ scope.row.type }}</span>
              <div v-if="scope.row.state !=3 || scope.row.deleted">
                <div class="layer">已失效</div>
              </div>
              </div>
              <div class="content">
                <h4 @click="goPath('/course/detail/'  + scope.row.uuid)">{{ scope.row.name }}</h4>
                <div class="info">
                  章节数：{{ scope.row.chapter_num }}章{{get_course_num(scope.row)}}节
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template #default="scope">
              <el-button class="btn_danger_common"
                @click="delFavor(scope.row.uuid)"
                type="danger">
                移除
              </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination :pager="pager" @getPager="getPager" ref="pagination">
        <el-dropdown class="pagination_dropdown" trigger="click" @command="handleCommandOrder">
          <span class="el-dropdown-link">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
<!--              <el-dropdown-item command="">移除失效课程</el-dropdown-item>-->
              <el-dropdown-item command="del" v-bind:disabled="disabledDel">移除</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </Pagination>
    </div>
  </div>
</template>

<script>
  import Pagination from 'components/common/Pagination'
  import { unlike, get_user_favors, get_invalid_favors } from "api/course/course/create"
  import { showConfirmMessageBox, showMessage } from 'api/base/message'
  export default {
    components: {
      Pagination
    },
    data() {
      return {
        list: [],
        selectedRows: [],
        pager: {

        },
        disabledDel: 'disabled',
      }
    },
    mounted() {
      this.pager = {
        pageSize: this.$refs.pagination.baseConfig.pageSize,
        currentPage: 1
      }
      this.setData()
    },
    methods: {
      checkbox(row, index){
        if (row.state != 3 || row.deleted){
          return false
        }else{
          return true
        }

      },
      get_course_num(row){
        let ret = row.live_num + row.face_num + row.video_num + row.auth_num + row.experiment_num
        return ret
      },
      setData(){
        const that = this;
        var data = {}
        data['offset'] = (that.pager.currentPage - 1) * that.pager.pageSize
        data['limit'] = that.pager.pageSize
        get_user_favors(data).then((res) => {
          console.log(res.data)
          that.list = res.data
          that.pager.total = res.total
        }).catch(err => {
          that.$alert(`${ err }`, '提示', {
            confirmButtonText: '确定',
          });
        });
      },
      delFavor(uuid) {
        var that = this
        showConfirmMessageBox('确定要移除吗？')
          .then(() => {
            if(uuid == 0){
              this.selectedRows.forEach(res => {
                unlike({'target_uuid': res.uuid, 'target_type': 'favor'}).then(res => {
                  that.setData()
                })
              })
            }else{
              unlike({'target_uuid': uuid, 'target_type': 'favor'}).then(res => {
                that.setData()
              })
            }

          })
      },
      goPath(path) {
        this.$router.push(path)
      },
      selectChange(val) {
        this.selectedRows = val
        if(this.selectedRows.length == 0){
          this.disabledDel = 'disabled'
        }else{
          this.disabledDel = false
        }
      },
      getPager(pager) { //获取分页变化后数据
        this.pager = pager
        this.setData()
      },
      handleCommandOrder(command) {  //批量操作
        if (command === 'del') {
          this.delFavor(0)
        }
      },
      delInvalidFavor(){
        var that = this
        showConfirmMessageBox('确定要移除吗？')
          .then(() => {
            get_invalid_favors().then((res) => {
              debugger
              res.data.forEach(favor => {
                unlike({'target_uuid': favor.uuid, 'target_type': 'favor'}).then(res => {
                  that.setData()
                })
              })
            })

          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .frame_title {
    .list_operate_btns {
      .el-button {
        float:right;
        color:#999;
        &:hover {
          color:#00adef;
        }
      }
    }
  }
</style>
